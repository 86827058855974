.contact__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
  margin-bottom: 10%;
  border: 2px solid #c64401;
  border-radius: 10px;
  width: 60%;
}
.contact__heading {
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  line-height: 5rem;
  font-style: italic;
  font-size: 4rem;
  line-height: 1.2em;
  color: #c64401;
  margin-bottom: 0;
}
.contact__wrapper > p {
  margin: 0;
  font-size: 0.8em;
}
.contact__btn {
  margin: 3% auto 7%;
}
/* ===== MEDIA QUERIES ===== */
@media (max-width: 920px) {
  .contact__wrapper {
    margin-bottom: 20%;
  }
}

@media (max-width: 820px) {
  .contact__wrapper {
    width: 70%;
    padding: 2%;
    margin-bottom: 20%;
  }
  .contact__btn {
    margin: 3% auto 12%;
  }
}

@media (max-width: 540px) {
  .contact__wrapper {
    width: 90%;
    padding: 2%;
    margin-bottom: 30%;
  }
  .contact__heading {
    font-size: 3rem;
    letter-spacing: -2px;
    margin-bottom: 3%;
  }
  .contact__wrapper > p {
    text-align: center;
  }
  .contact__btn {
    margin: 5% auto 7%;
  }
}

@media (max-width: 360px) {
  .contact__heading {
    letter-spacing: -3px;
  }
}
