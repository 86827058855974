@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
Orange : #C64401
Cream: #fff3e0 
Cement: #ccc0ae 

====FONT IMPORTS====
  // main headings ======> font-family: 'IBM Plex Serif', serif;
  // main text ======> font-family: 'Open Sans', sans-serif;

*/

html {
  scroll-behavior: smooth;
}

.body {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  background-color: #fff3e0;
  margin: 0;
}

@media (max-width: 319px) {
  .statement {
    font-size: 12px;
  }
  .intro {
    font-size: 40px;
  }
  .job-title {
    letter-spacing: 0px;
  }
  .nav {
    margin-top: 10px;
  }
  .nav-bar {
    font-size: 13px;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 5px;
  }
}

@media (min-width: 414px) {
  .statement {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .statement {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .statement {
    font-size: 23px;
  }
}

.header__wrapper {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #fff3e0;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.header__dmc {
  font-size: 50px;
  color: #C64401;
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  padding: 1%;
  transition: all 0.4s ease;
  text-decoration: none;
}
.dmc:hover {
  background-color: #C64401;
  color: #fff3e0;
}

nav {
  overflow: hidden;
  background-color: #fff3e0;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 10vh;
  z-index: 1;
}

.nav__wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content:center;
  padding: .5% auto;
}

button {
  font-family: "Open Sans", sans-serif;
  background-color: #c64401;
  border: 2px solid #c64401;
  color: #fff3e0;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.2px;
  border-radius: 5em;
  padding: 1em 3em;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
}

button:hover {
  background-color: #fff3e0;
  color: #c64401;
  border: 2px solid #c64401;
}

.nav__icons-wrapper {
  width: 24%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.fa-icon {
  font-size: 50px;
  position: relative;
  top: 0;
  transition: all 0.2s ease-in-out;
  color: #c64401;
}

.fa-icon:hover {
  top: -5px;
}

/*===== MEDIA QUERIES ===== */

@media (max-width: 1024px) {
}

@media (max-width: 500px) {
  .nav__work-btn,
  .nav__resume-btn {
    padding: 0.5em;
    font-size: 20px;
  }
  .nav__wrapper {
    align-items: center;
    padding: 4%;
    margin: 0;
  }
  .nav__button-a {
    padding: 0;
  }
  .nav__icons-wrapper {
    width: 35%;
  }
}

@media (max-width: 418px) {
  .nav__wrapper {
    padding: 0;
  }
}

@media (max-width: 280px) {
  .nav__wrapper {
    padding: 1%;
  }
  .nav__work-btn,
  .nav__resume-btn {
    padding: 0.5em;
    font-size: 18px;
  }
}

.hero__wrapper {
  background-color: #c64401;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0;
  height: 92vh;
  position: relative;
}
.hero__hello{
  margin-bottom: 0;
  margin-left: 3%;
}
.hero__hello,
.hero__job-title {
  color: #fff3e0;
  font-size: 1.2em;
  letter-spacing: 0.2em;
  margin-top: 0;
  position: relative;
  margin-left: 3%;
}

h1 {
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  color: #fff3e0;
  margin: 0;
  font-size: 5em;
  position: relative;
  margin-left: 2%;
}

.hero__job-title { 
  margin-bottom: 6%;
  position: relative;
}

/*===== ANIMATIONS ===== */

.animate{
  -webkit-animation: animate-slide 1.5s 1;
          animation: animate-slide 1.5s 1;
  -webkit-animation-name: animate-slide;
          animation-name: animate-slide;
}

.animate-slide{
  -webkit-animation-name: animate-slide;
          animation-name: animate-slide;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}

@-webkit-keyframes animate-slide{
  from { left: 100%;}
  to { left: 0%;}
}

@keyframes animate-slide{
  from { left: 100%;}
  to { left: 0%;}
}



/*===== MEDIA QUERIES ===== */

@media (max-width: 1024px) {
  .hero__wrapper {
    height: 90vh;
  }
}

@media (max-width: 800px) {
  .hero__wrapper {
    height: 55vh;
    width: 100vw;
  }
}

@media (max-width: 769px) {
  .hero__wrapper {
    height: 90vh;
    width: 100vw;
  }
}


@media (max-width: 500px) {
  .hero__wrapper {
    height: 85vh;
  }
  .hero__hello,
  .hero__job-title {
    font-size: 18px;
    letter-spacing: 0.02em;
  }
  h1 {
    font-size: 3.5rem;
  }
}


@media (max-width: 418px) {
  .hero__wrapper {
    height: 85vh;
  }
}
.about-section {
  display: flex;
  height: 80vh;
  margin-top: -3%;
}
.question-block {
  width: 50%;
  height: 100%;
  display: flex;
  background-color: #c64401;
  justify-content: flex-end;
  align-items: center;
}
.question-text {
  text-align: right;
  font-size: 4em;
  padding: 5%;
  font-weight: 500;
  font-style: italic;
  color: #fff3e0;
  font-family: "IBM Plex Serif", serif;
}
.about__info-wrapper {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 2%;
}
.about__statement {
  width: 60%;
  font-size: 0.8em;
  border-top: 0.01em solid #000000;
  border-bottom: 0.01em solid #000000;
  padding: 25px 0;
  margin: 0;
  text-align: justify;
}
.line {
  border-left: 0.01em solid #000000;
  height: 40%;
  position: absolute;
  top: 30.5%;
  max-height: 142px;
}

.about-photo {
  width: 30%;
  border: 0.01em solid #000000;
  border-radius: 50%;
  padding: 8%;
}

/*===== MEDIA QUERIES ===== */

@media (max-width: 920px) {
.about__info-wrapper {
  align-content: center;
}
  .about-photo {
    margin: 5%;
    width: 70%;
  }
}
@media (max-width: 820px) {
  .about-section {
    height: 65vh;
    padding-top: 0;
  }

  .question-block {
    margin-top: -1%;
  }

  .question-text {
    font-size: 3em;
  }

  .about-photo {
    margin-top: 5%;
    width: 70%;
  }

  .about__statement {
    width: 70%;
  }

  .about__info-wrapper {
    align-content: space-evenly;
  }
}
@media (max-width: 500px) {
  .about-section {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-wrap: wrap-reverse;
  }

  .question-block {
    width: 100vw;
    height: 50vh;
    justify-content: center;
    margin-top: -5%;
  }

  .question-text {
    margin-bottom: 0;
  }

  .about__info-wrapper {
    width: 100vw;
  }

  .about__statement {
    margin-top: 5%;
    width: 70%;
  }
}

@media (max-width: 375px) {
  .question-text {
    margin-bottom: 20%;
  }

  .question-block {
    height: 80vh;
  }
}

.project__card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #fff3e0;
  border-radius: 10px;
  z-index: 1;
  margin: 10px;
  padding: 2%;
  width: 40%;
}
.project__title {
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  font-size: 1.5rem;
  text-decoration: underline;
  transition: all ease-in-out 0.25s;
  cursor: pointer;
}
.project__title:hover {
  background-color: #c64401;
  color: #ffff;
}
.project__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.project__container .project__wrapper {
  position: relative;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  width: 100%;
}
.project__container .project__wrapper:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
.project__text {
  text-align: justify;
  font-family: "Open Sans", sans-serif;
  font-size: .8em;
  align-self: center;
}
.project__buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

/*===== MEDIA QUERIES ===== */

@media (max-width: 500px) {
  .project__card {
    width: 90%;
    margin: 15px;
  }
}

.section-intro {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  background-color: #C64401;
}

.vertical-line {
  border-left: 0.01em solid #fff3e0;
  height: 65%;
  position: absolute;
  left: 50.3%;
  margin-left: -3px;
}

.horizontal-line {
  border-top: 0.01em solid #fff3e0;
  width: 50%;
  margin-top: 9.5%;
  margin-left: 25%;
  padding-bottom: 5%;
}

.work__heading {
  margin: 0 auto;
  text-align: center;
  margin-top: 14%;
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  font-style: italic;
  font-size: 4em;
  background-color: #c64401;
  color: #fff3e0;
  padding-bottom: 5%;
}

.work__section-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  background-color: #c64401;
  padding-bottom: 10%;
  margin-top: -1%;
}

.work__project-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100vw;
}

.work__color-section {
  width: 50vw;
  height: 99.9%;
  background-color: #c64401;
  position: absolute;
}

/*===== MEDIA QUERIES ===== */

@media (max-width: 500px) {
  .work__color-section {
    width: 100vw;
  }
}

@media (max-width: 375px) {
  .work__heading {
    font-size: 3em;
  }
}

.contact__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
  margin-bottom: 10%;
  border: 2px solid #c64401;
  border-radius: 10px;
  width: 60%;
}
.contact__heading {
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  line-height: 5rem;
  font-style: italic;
  font-size: 4rem;
  line-height: 1.2em;
  color: #c64401;
  margin-bottom: 0;
}
.contact__wrapper > p {
  margin: 0;
  font-size: 0.8em;
}
.contact__btn {
  margin: 3% auto 7%;
}
/* ===== MEDIA QUERIES ===== */
@media (max-width: 920px) {
  .contact__wrapper {
    margin-bottom: 20%;
  }
}

@media (max-width: 820px) {
  .contact__wrapper {
    width: 70%;
    padding: 2%;
    margin-bottom: 20%;
  }
  .contact__btn {
    margin: 3% auto 12%;
  }
}

@media (max-width: 540px) {
  .contact__wrapper {
    width: 90%;
    padding: 2%;
    margin-bottom: 30%;
  }
  .contact__heading {
    font-size: 3rem;
    letter-spacing: -2px;
    margin-bottom: 3%;
  }
  .contact__wrapper > p {
    text-align: center;
  }
  .contact__btn {
    margin: 5% auto 7%;
  }
}

@media (max-width: 360px) {
  .contact__heading {
    letter-spacing: -3px;
  }
}

.tech__heading {
  margin: 5% auto;
  margin-bottom: 0;
  text-align: center;
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  font-style: italic;
  font-size: 4rem;
  color: #c64401;
}
.tech__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 5%;
}
.tech__wrapper > * {
  padding: 1%;
}

@media (max-width: 500px) {
  .tech__heading {
    font-size: 3rem;
    padding-top: 10%;
  }
}

