.section-intro {
  width: 100vw;
  height: fit-content;
  position: absolute;
  background-color: #C64401;
}

.vertical-line {
  border-left: 0.01em solid #fff3e0;
  height: 65%;
  position: absolute;
  left: 50.3%;
  margin-left: -3px;
}

.horizontal-line {
  border-top: 0.01em solid #fff3e0;
  width: 50%;
  margin-top: 9.5%;
  margin-left: 25%;
  padding-bottom: 5%;
}
