.header__wrapper {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #fff3e0;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.header__dmc {
  font-size: 50px;
  color: #C64401;
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  padding: 1%;
  transition: all 0.4s ease;
  text-decoration: none;
}
.dmc:hover {
  background-color: #C64401;
  color: #fff3e0;
}
