.project__card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #fff3e0;
  border-radius: 10px;
  z-index: 1;
  margin: 10px;
  padding: 2%;
  width: 40%;
}
.project__title {
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  font-size: 1.5rem;
  text-decoration: underline;
  transition: all ease-in-out 0.25s;
  cursor: pointer;
}
.project__title:hover {
  background-color: #c64401;
  color: #ffff;
}
.project__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.project__container .project__wrapper {
  position: relative;
  filter: grayscale(1);
  width: 100%;
}
.project__container .project__wrapper:hover {
  filter: grayscale(0);
}
.project__text {
  text-align: justify;
  font-family: "Open Sans", sans-serif;
  font-size: .8em;
  align-self: center;
}
.project__buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

/*===== MEDIA QUERIES ===== */

@media (max-width: 500px) {
  .project__card {
    width: 90%;
    margin: 15px;
  }
}
