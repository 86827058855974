.about-section {
  display: flex;
  height: 80vh;
  margin-top: -3%;
}
.question-block {
  width: 50%;
  height: 100%;
  display: flex;
  background-color: #c64401;
  justify-content: flex-end;
  align-items: center;
}
.question-text {
  text-align: right;
  font-size: 4em;
  padding: 5%;
  font-weight: 500;
  font-style: italic;
  color: #fff3e0;
  font-family: "IBM Plex Serif", serif;
}
.about__info-wrapper {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 2%;
}
.about__statement {
  width: 60%;
  font-size: 0.8em;
  border-top: 0.01em solid #000000;
  border-bottom: 0.01em solid #000000;
  padding: 25px 0;
  margin: 0;
  text-align: justify;
}
.line {
  border-left: 0.01em solid #000000;
  height: 40%;
  position: absolute;
  top: 30.5%;
  max-height: 142px;
}

.about-photo {
  width: 30%;
  border: 0.01em solid #000000;
  border-radius: 50%;
  padding: 8%;
}

/*===== MEDIA QUERIES ===== */

@media (max-width: 920px) {
.about__info-wrapper {
  align-content: center;
}
  .about-photo {
    margin: 5%;
    width: 70%;
  }
}
@media (max-width: 820px) {
  .about-section {
    height: 65vh;
    padding-top: 0;
  }

  .question-block {
    margin-top: -1%;
  }

  .question-text {
    font-size: 3em;
  }

  .about-photo {
    margin-top: 5%;
    width: 70%;
  }

  .about__statement {
    width: 70%;
  }

  .about__info-wrapper {
    align-content: space-evenly;
  }
}
@media (max-width: 500px) {
  .about-section {
    height: fit-content;
    flex-wrap: wrap-reverse;
  }

  .question-block {
    width: 100vw;
    height: 50vh;
    justify-content: center;
    margin-top: -5%;
  }

  .question-text {
    margin-bottom: 0;
  }

  .about__info-wrapper {
    width: 100vw;
  }

  .about__statement {
    margin-top: 5%;
    width: 70%;
  }
}

@media (max-width: 375px) {
  .question-text {
    margin-bottom: 20%;
  }

  .question-block {
    height: 80vh;
  }
}
