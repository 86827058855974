/*
Orange : #C64401
Cream: #fff3e0 
Cement: #ccc0ae 

====FONT IMPORTS====
  // main headings ======> font-family: 'IBM Plex Serif', serif;
  // main text ======> font-family: 'Open Sans', sans-serif;

*/
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Open+Sans&display=swap");

html {
  scroll-behavior: smooth;
}

.body {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  background-color: #fff3e0;
  margin: 0;
}

@media (max-width: 319px) {
  .statement {
    font-size: 12px;
  }
  .intro {
    font-size: 40px;
  }
  .job-title {
    letter-spacing: 0px;
  }
  .nav {
    margin-top: 10px;
  }
  .nav-bar {
    font-size: 13px;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 5px;
  }
}

@media (min-width: 414px) {
  .statement {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .statement {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .statement {
    font-size: 23px;
  }
}
