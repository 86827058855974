.hero__wrapper {
  background-color: #c64401;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0;
  height: 92vh;
  position: relative;
}
.hero__hello{
  margin-bottom: 0;
  margin-left: 3%;
}
.hero__hello,
.hero__job-title {
  color: #fff3e0;
  font-size: 1.2em;
  letter-spacing: 0.2em;
  margin-top: 0;
  position: relative;
  margin-left: 3%;
}

h1 {
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  color: #fff3e0;
  margin: 0;
  font-size: 5em;
  position: relative;
  margin-left: 2%;
}

.hero__job-title { 
  margin-bottom: 6%;
  position: relative;
}

/*===== ANIMATIONS ===== */

.animate{
  animation: animate-slide 1.5s 1;
  animation-name: animate-slide;
}

.animate-slide{
  animation-name: animate-slide;
  animation-timing-function: ease;
}

@keyframes animate-slide{
  from { left: 100%;}
  to { left: 0%;}
}



/*===== MEDIA QUERIES ===== */

@media (max-width: 1024px) {
  .hero__wrapper {
    height: 90vh;
  }
}

@media (max-width: 800px) {
  .hero__wrapper {
    height: 55vh;
    width: 100vw;
  }
}

@media (max-width: 769px) {
  .hero__wrapper {
    height: 90vh;
    width: 100vw;
  }
}


@media (max-width: 500px) {
  .hero__wrapper {
    height: 85vh;
  }
  .hero__hello,
  .hero__job-title {
    font-size: 18px;
    letter-spacing: 0.02em;
  }
  h1 {
    font-size: 3.5rem;
  }
}


@media (max-width: 418px) {
  .hero__wrapper {
    height: 85vh;
  }
}