.work__heading {
  margin: 0 auto;
  text-align: center;
  margin-top: 14%;
  font-family: "IBM Plex Serif", serif;
  font-weight: 500;
  font-style: italic;
  font-size: 4em;
  background-color: #c64401;
  color: #fff3e0;
  padding-bottom: 5%;
}

.work__section-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  background-color: #c64401;
  padding-bottom: 10%;
  margin-top: -1%;
}

.work__project-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100vw;
}

.work__color-section {
  width: 50vw;
  height: 99.9%;
  background-color: #c64401;
  position: absolute;
}

/*===== MEDIA QUERIES ===== */

@media (max-width: 500px) {
  .work__color-section {
    width: 100vw;
  }
}

@media (max-width: 375px) {
  .work__heading {
    font-size: 3em;
  }
}
