nav {
  overflow: hidden;
  background-color: #fff3e0;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 10vh;
  z-index: 1;
}

.nav__wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content:center;
  padding: .5% auto;
}

button {
  font-family: "Open Sans", sans-serif;
  background-color: #c64401;
  border: 2px solid #c64401;
  color: #fff3e0;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.2px;
  border-radius: 5em;
  padding: 1em 3em;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
}

button:hover {
  background-color: #fff3e0;
  color: #c64401;
  border: 2px solid #c64401;
}

.nav__icons-wrapper {
  width: 24%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.fa-icon {
  font-size: 50px;
  position: relative;
  top: 0;
  transition: all 0.2s ease-in-out;
  color: #c64401;
}

.fa-icon:hover {
  top: -5px;
}

/*===== MEDIA QUERIES ===== */

@media (max-width: 1024px) {
}

@media (max-width: 500px) {
  .nav__work-btn,
  .nav__resume-btn {
    padding: 0.5em;
    font-size: 20px;
  }
  .nav__wrapper {
    align-items: center;
    padding: 4%;
    margin: 0;
  }
  .nav__button-a {
    padding: 0;
  }
  .nav__icons-wrapper {
    width: 35%;
  }
}

@media (max-width: 418px) {
  .nav__wrapper {
    padding: 0;
  }
}

@media (max-width: 280px) {
  .nav__wrapper {
    padding: 1%;
  }
  .nav__work-btn,
  .nav__resume-btn {
    padding: 0.5em;
    font-size: 18px;
  }
}
